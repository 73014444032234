import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../services/event.service';
import { NavService } from '../../services/nav.service';
import { languages } from '../../constants/language';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthService, SubscriptionService, UserService } from '../../services';
import { CryptoUserService } from '../../services/cryptoUser.service';
import { CryptoUserModel, HeaderNotificationModel, NotificationModel } from '../../model';
import { BaseComponent } from '../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent extends BaseComponent implements OnInit {

  faBars = faBars;
  public logOutIcon: string = '../../../../assets/icons/logout_header.png';
  public cryptoUser: () => CryptoUserModel;

  constructor(
    public navService: NavService,
    private translate: TranslateService,
    private eventService: EventService,
    public authService: AuthService,
    public cryptoUserService: CryptoUserService,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    public router: Router,
    private userService: UserService
  ) {
    super(translate, modalService);
    var t = this;
    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get())
        return t.cryptoUserService.get();
      else return new CryptoUserModel();
    }
  }


  ngOnInit(): void {
  }

  getAvatar(): string{
    var t = this;
    if(t.cryptoUser().avatarUrl)
      return t.cryptoUser().avatarUrl;
    else return t.defaultAvatarUrl;
  }
}
