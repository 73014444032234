export class StatsModel {

  public gasPrice: number;
  public realGasPrice: number;
  public compensationCoef: number;
  public gasPriceEthw: number;
  public nox2eth: string;
  public nox2btc: string;
  public nox2usd: string;
  public gasPriceHighLimit: number;

  public gasPriceForBNB: number;
  public bnb2usd: string;

  public btc: string;
  public usd: string;
  public total: string;
  public investors: string;

  public saleNox: string;
  public percentSaleNox: string;

  public eth2usd: number;
  public ethw2usd: number;
  public ripeToUsdt: number;
  public ripeToUsd: number; 
  public usdtToUsd: number; 
  
  dateRefresh: number;
  periodofhour: string;

  actualRateInfoId: number;
  mRate: number;

  //комиссии
  public cashflowFee: number;
  public managmentFee: number;
  public exitFee: number;
  public entryFee: number; 
  public successFee: number; 
  public maxTransFeeUsdt: number; 
  public distributionFee: number;
}
