<div class="card mx-auto max-w-400">
  <div class="card-header">
    <div class="f-20 text-center text-uppercase">{{'Identity verification' | lang}}</div>
  </div>
  <div class="card-body mt-3 f-16">
    <div class="row">
      <div class="col">
        {{ modalText | lang}}
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-6">
        <button class="btn btn-outline-primary w-100" type="button" (click)="activeModal.close()">
          {{'buttons.cancel' | lang}}                                
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" type="button" (click)="verify()">
          {{'buttons.verify' | lang}}                                
        </button>
      </div>              
    </div>      
  </div>   
</div>   
