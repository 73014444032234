import { DepositTabModel } from "../../../model/depositTabModel";


export enum DepositTabId {
    FIAT_TAB_ID = 0,
    CRYPTO_TAB_ID = 1,
    SWAP_TAB_ID = 2,
    CRYTO_DEPOSIT_TAB_ID = 3
  }

export const depositModalTabs: DepositTabModel[] = [
    new DepositTabModel({id: DepositTabId.FIAT_TAB_ID, imageUrl:"assets/images/ripe/icons/bank_card.svg", name:"Fiat", active: false, subtabs:[]}),
    new DepositTabModel({id: DepositTabId.CRYPTO_TAB_ID, imageUrl:"assets/images/ripe/icons/bitcoin.svg", name:"Crypto", active: false, 
        subtabs: [
            new DepositTabModel({id: DepositTabId.CRYTO_DEPOSIT_TAB_ID, imageUrl:"assets/images/ripe/icons/deposit.svg", name:"Deposit", active: false, subtabs:[]}),
            new DepositTabModel({id: DepositTabId.SWAP_TAB_ID, imageUrl:"assets/images/ripe/icons/swap.svg", name:"Swap", active: false, subtabs:[]})
        ]}
    )
];