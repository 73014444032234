import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { CryptoUserService, EventService } from '../../services';

@Component({
  selector: 'app-spotify-player',
  templateUrl: './spotify-player.component.html',
  styleUrls: ['./spotify-player.component.scss']
})
export class SpotifyPlayerComponent implements  AfterViewInit, OnDestroy, OnInit {
  @ViewChild('spotifyPlayer', { static: true }) player: ElementRef;
  embedController: any;
  isHidden: boolean = true;
  isHiddenSupport: boolean = false;
  subscription: Subscription;

  constructor( 
    private eventService: EventService,
    private cryptoUserService: CryptoUserService,
  ) {
    var t = this;

    t.subscription = t.eventService.SongChangeEvent.subscribe((songUri: string) => {
      t.initHeightInvisibleBlock();
    });
    t.subscription = t.eventService.SongChangeEvent.subscribe((songUri: string) => {
      t.changeSong(songUri);
    });
  }
  
  ngOnInit(): void {
    this.initTidioChat();
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    var t = this;
    t.initHeightInvisibleBlock();
  }

  initHeightInvisibleBlock(){
    let spotifyInvisibleBlockForMobile = document.getElementById('spotifyInvisibleBlockForMobile');
    if (!spotifyInvisibleBlockForMobile)
      return;
    let sidebarForMobile = document.getElementById('sidebarForMobile');
    spotifyInvisibleBlockForMobile.style.height = !!sidebarForMobile ?
      `${sidebarForMobile.clientHeight + sidebarForMobile.offsetTop * 2}px` : `0px`;
  }

  ngOnDestroy() {
    if (!!(<any>window).SpotifyIframeConfig) {
      (<any>window).SpotifyIframeConfig = undefined;
    }

    this.embedController?.destroy();
    this.subscription?.unsubscribe();
  }

  initPlayerAndStart(uri: string) {
    var t = this;
    (<any>window).onSpotifyIframeApiReady = (IFrameAPI) => {
      let element = document.getElementById('spotify-player');
      let options = {
        uri: 'spotify:track:' + uri,
        height: 90,
        width: '100%',
        theme: 'dark'
      };

      let callback = (EmbedController) => {
        t.embedController = EmbedController;
        t.startSong(uri);
      };

      IFrameAPI.createController(element, options, callback);
    };
  }

  close(): void {
    var t = this;
    t.isHidden = true;
    t.embedController.pause();
    t.eventService.SongPlayerClose.emit();
  }

  createPlayerScript() {
    const playerScript = document.createElement('script');
    playerScript.src = "https://open.spotify.com/embed-podcast/iframe-api/v1";
    playerScript.async = true;
    this.player.nativeElement.appendChild(playerScript);
  }

  changeSong(uri: string) {
    var t = this;
    if(!!t.embedController)
      t.startSong(uri);
    else
      t.initPlayerAndStart(uri);
  }

  startSong(uri){
    var t = this;
    t.embedController.loadUri("spotify:track:" + uri);
    t.isHidden = false;
    t.embedController.play();
  }

  ngAfterViewInit(): void {
    this.createPlayerScript();
  }
  
  initTidioChat(): void {
    let t = this;
    const onTidioChatApiReady = () => {
      (window as any).tidioChatApi.hide();

      (window as any).tidioChatApi.on('close', () => {
        (window as any).tidioChatApi.hide();
        t.resetStyles();
        t.isHiddenSupport = false;
      });
    };

    const user = t.cryptoUserService.get();
    if (user) {
      (window as any).tidioChatApi.setVisitorData({
        name: user.firstname,
        phone: user.phone,
        email: user.email,
        customAttributes: {
          id: user.id,
          typeAuthorization: user.typeAuthorization,
          twoFactorEnabled: user.twoFactorEnabled
        }
      });
    }
    if ((window as any).tidioChatApi) {
      (window as any).tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady);
    }
  }

  openChat(): void {
    if ((window as any).tidioChatApi) {
      this.isHiddenSupport = true;
      (window as any).tidioChatApi.show();
      (window as any).tidioChatApi.open();
    }
  }
  
  // костыль, чтобы убрать стили, которые вешает сама чатра и не убирает
  resetStyles(): void {
    const resetStyleProperties = (element: HTMLElement, properties: string[]) => {
      properties.forEach(prop => element.style[prop as any] = '');
    };
  
    const body = document.body;
    const html = document.documentElement;
    const propertiesToReset = ['overflow', 'height', 'width', 'visibility', 'display', 'inset', 'margin', 'opacity'];
  
    resetStyleProperties(body, propertiesToReset);
    resetStyleProperties(html, ['overflow', 'margin']);
  }
}
