import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '../../services';
import { documentType } from '../../constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  currentYear: number;
  docNames = new Map();
  existingTypes : number[] = [];
  documentType = documentType;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private documentService: DocumentService
  ) { 
    super(translate, modalService);
    this.currentYear = new Date().getFullYear();
  }
  

  ngOnInit(): void {
    let t = this;
    t.documentService.docsExistenceCheck()
      .then(response => {
        t.existingTypes = response.data;
        t.existingTypes.forEach(type => {
          let label = t.documentType.find(x => x.value === type).label;
          t.docNames.set(type, label);
        })
      })
      .catch(ex => {
        t.showResponseError(ex);
      })
  }
}
