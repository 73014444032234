<h2 class="align-center auth-bg-title mb-4">{{'Admin' | lang}}</h2>
<div class="card-body">
  <form class="theme-form" [formGroup]="loginForm">
    <div class="form-group">
      <input id="email-input-field" type="email" class="form-control" formControlName="email" type="email"
        placeholder="{{'Email' | lang}}" required="" autofocus="">
      <div *ngIf="elemIsInvalid(loginForm.controls.email)" class="text text-danger mt-1">
        {{textErrorStr(loginForm.controls.email)}}
      </div>
    </div>

    <div class="form-group">
      <div class="input-pass">
        <div style="position: relative;">
          <input placeholder="{{'Password' | lang}}" [type]="!showPass? 'password' : 'text'" class="form-control"
            formControlName="password">
          <!-- <img (click)="hideOrShowPassword()"
            [src]="showPass? '/assets/images/auth/icon_eye_closed.svg' : '/assets/images/auth/icon_eye.svg'"
            style="position: absolute;  top: 13px; right: 13px; height: 22px; opacity: 0.2;" draggable="false"> -->
        </div>
      </div>
      <div *ngIf="elemIsInvalid(loginForm.controls.password)" class="text text-danger mt-1">
        {{textErrorStr(loginForm.controls.password)}}
      </div>
    </div>

    <div class="form-group" *ngIf="needConfirm">
      <label for="inputConfirmCode" class="login-sr-only">{{'Confirmation code' | lang}} {{typeTwoFactorAuth}}</label>
      <input id="inputConfirmCode" type="text" class="form-control" formControlName="confirmationCode" placeholder="{{'Confirmation code' | lang}}"
        required>
      <div *ngIf="elemIsInvalid(loginForm.controls.confirmationCode)" class="text text-danger mt-1">
        {{textErrorStr(loginForm.controls.confirmationCode)}}
      </div>
    </div>
    
    <div [hidden]="!showReCAPTCHA" class="pb-3" id="captchaField" style="text-align: -webkit-center;"></div>

    <div class="form-group">
      <button id="login-button" class="btn btn-primary btn-block" type="submit"
        [class.loader--text]="authService.showLoader" (click)="login()" type="submit">{{'Sign in' | lang}}</button>
    </div>
  </form>
</div>