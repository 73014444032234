import { Injectable } from '@angular/core';
import { IResponse } from "../interfaces";
import { ApiService } from './api.service';
import { BaseFilterModel, StatsModel} from '../model'
import { FundStatisticModel } from '../model/statistic.model';
import { UsersStatsModel } from '../model/usersStats.model';


const statApiUrl = 'api/stat/';
const cryptoUserApiUrl = 'api/cryptoUser/';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  private stats: StatsModel;
  constructor(private api: ApiService) {
  }

public clear(){
  this.stats=undefined;
}
  /** Инициализировать сервис */
  public initStats(): Promise<StatsModel> {
    var promise: Promise<StatsModel>;
    if (this.getStats() == undefined || this.getStats() == null) {
      promise = this.refreshStats();
    } else {
      promise = new Promise<StatsModel>((resolve, reject) => {
        resolve(this.getStats())
      });
    }
    return promise;
  }

  /**
 * обновление статистики
 * @returns
 */
  public refreshStats = (): Promise<StatsModel> => {
    var t = this;
    return new Promise<StatsModel>((resolve, reject) => {
      this.api.get<StatsModel>(cryptoUserApiUrl + "stats").toPromise()
        .then((resp) => {
          t.stats = resp.data;
          resolve(resp.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  }

  public getStats(): StatsModel {
    return this.stats;
  }

  public getFundStatistic(): Promise<IResponse<FundStatisticModel>> {
    return this.api.get<FundStatisticModel>(statApiUrl + "getfundstats").toPromise();
  }

  public getUsersStats(filter: BaseFilterModel): Promise<IResponse<UsersStatsModel>> {
    return this.api.post<UsersStatsModel>(cryptoUserApiUrl + "getUsersStats", filter).toPromise();
  }
}