import { Injectable } from '@angular/core';
import { InfoAnalyticTypeEnum } from '../enums';
import { IResponse } from '../interfaces';
import { BaseFilterModel, InfoAnalyticRow,
  TotalRevenueModel, InfoAnalyticFilter} from '../model';
import { ApiService } from './api.service';

import { UsersRegistryModel } from '../model'; //позже удалить

const infoAnalyticApi = 'api/infoanalitic/';

@Injectable({
  providedIn: 'root'
})
export class InfoAnalyticsService {
  constructor(private api: ApiService) {
  }

  public getTotalRevenue(year: number): Promise<IResponse<TotalRevenueModel[]>> {
    return this.api.get<TotalRevenueModel[]>(infoAnalyticApi + "gettotalrevenue/" + year).toPromise();
  }

  public getProductInfoAnalytic(filter: InfoAnalyticFilter): Promise<IResponse<InfoAnalyticRow>> {
    return this.api.post<InfoAnalyticRow>(infoAnalyticApi + "getProductInfoAnalytic", filter).toPromise();
  }

  public getUsersRegistry(dateFilter: BaseFilterModel): Promise<IResponse<UsersRegistryModel[]>> {
    return this.api.post<UsersRegistryModel[]>(infoAnalyticApi + 'getusersregistry', dateFilter).toPromise();
  }

  public getCommissionReport(dateFilter: BaseFilterModel): Promise<IResponse<string>> {
    return this.api.post<string>(infoAnalyticApi + 'getCommissionReport', dateFilter).toPromise();
  }

  public getAnalyticsReport(dateFilter: BaseFilterModel): Promise<IResponse<string>> {
    return this.api.post<string>(infoAnalyticApi + 'getAnalyticsReport', dateFilter).toPromise();
  }

  public getUsersReport(): Promise<IResponse<string>> {
    return this.api.get<string>(infoAnalyticApi + 'getUsersReport').toPromise();
  }
}