import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../services/event.service';
import { NavService } from '../../services/nav.service';
import { languages } from '../../constants/language';
import { environment } from 'src/environments/environment';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthService, SubscriptionService, UserService } from '../../services';
import { CryptoUserService } from '../../services/cryptoUser.service';
import { CryptoUserModel, HeaderNotificationModel, NotificationModel } from '../../model';
import { BaseComponent } from '../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NotifType } from '../../enums/notifType';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  faBars = faBars;

  public currentUserLang: string;
  public currentLangIcon: string;
  public externalUrls  = environment.externalUrls;
  public languages = languages;
  public logOutIcon: string = '../../../../assets/icons/logout_header.png';
  public cryptoUser: () => CryptoUserModel;
  public headerNotification: HeaderNotificationModel = new HeaderNotificationModel();
  public notifTypeEnum = NotifType;
  constructor(
    public navService: NavService,
    private translate: TranslateService,
    private eventService: EventService,
    public authService: AuthService,
    public cryptoUserService: CryptoUserService,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    public router: Router,
    private userService: UserService
  ) {
    super(translate, modalService);
    var t = this;
    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get())
        return t.cryptoUserService.get();
      else return new CryptoUserModel();
    }
    t.headerNotification.notCheckedCount = 0;
    t.headerNotification.notifications = [];
  }


  ngOnInit(): void {
    this.getNotifications('en');
    //закоменчено пока не добавятся стили для попапа уведомелний
  }

  getAvatar(): string{
    var t = this;
    if(t.cryptoUser().avatarUrl)
      return t.cryptoUser().avatarUrl;
    else return t.defaultAvatarUrl;
  }

  getNotifications(lang) {
    var t = this;
    t.subscriptionService.getLocalizedNotifications(lang)
      .then(resp => {
        t.headerNotification = resp.data;
      })
      .catch(er => {
        // t.showResponseError(er);
      })
  }
  checkNotification(notif: NotificationModel) {
    if (notif.isChecked) return;
    notif.isChecked = true;
    var t = this;
    t.subscriptionService.checkNotification(notif.id)
      .then(resp => {
        if (resp.data) {
          t.headerNotification.notCheckedCount--;
        }
      })
      .catch(er => {
        // t.showError(er);
      })
  }

  checkAllNotifications() {
    var t = this;
    t.setLoading(true);
    t.subscriptionService.checkAllNotifications()
      .then(resp => {
        if (resp.data) {
          t.headerNotification.notCheckedCount = 0;
          t.headerNotification.notifications.map(x => x.isChecked = true);
          t.showSuccess("All notifications checked");
        }
      })
      .catch(er => t.showResponseError(er))
      .finally(() => t.setLoading(false));
  }

  toSettings() {
    this.router.navigate(['user-profile/security']);
  }

  toProfile() {
    this.router.navigateByUrl('user-profile/info');
  }

  collapseSidebar() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
    this.navService.showNotif = false;
  }

  public showSurveyModal() {
    this.eventService.HeaderButtonSurveyEvent.emit();
  }
}
