import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { BaseComponent } from '../../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-banner-modal',
  templateUrl: "banner.modal.html",
  styleUrls: ["./banner.modal.scss"]
})
export class BannerModal extends BaseComponent implements OnInit {

  @Input() imgUrl: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private modalService : NgbModal
  ) {
    super(translate, modalService)
  }

  ngOnInit() {
  }

  public close() {
    this.activeModal.close();
  }
}