import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderButtonModel } from '../../model';
import { EventService, NavService } from '../../services';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public firstButton: HeaderButtonModel = new HeaderButtonModel();
  @Input()
  public secondButton: HeaderButtonModel = new HeaderButtonModel();
  @Input()
  public showInput: boolean = false;
  constructor(
    private router: Router,
    private eventService: EventService,
    private navService: NavService,
  ) { }

  ngOnInit(): void {
  }

  public emitButton(button: HeaderButtonModel){
    var t = this;
    t.eventService.HeaderButtonEvent.emit(button.funcName);
    if(!!button.switcherTitle){
      button.needShow = false;
      t.navService.HEADERBUTTONS.find(x => x.itemTitle == button.switcherTitle).needShow = true;
    }
  }

  public emitUpload(file: any){
    var t = this;
    t.eventService.UploadFileEvent.emit(file);
  }
}
