import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy, OnInit, } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../base/base.component';
import { BuyPostRequest, CryptoUserModel, QuestionModelDict, StatsModel, SurveyPostRequest, SurveyProgressModel, Track } from 'src/app/shared/model';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { IndexType, SurveyType } from 'src/app/shared/enums';
import { CryptoUserService, EventService, IndexService, StatsService, TrackService, WalletService } from 'src/app/shared/services';
import { IndexInfo, currencyType } from 'src/app/shared/constants';
import { DepositModal } from '../deposit-modal';
import { PurchaseModal } from '../purchase-modal';
import { VerificateModal } from '../verificate-modal';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { environment } from 'src/environments/environment';
import { TracksFilterModel } from 'src/app/shared/model/trackFilter.model';
import { FacebookAnalyticsService } from 'src/app/shared/services/facebook-analytics.service';

@Component({
  selector: 'app-survey-modal',
  templateUrl: "survey.modal.html",
  styleUrls: ["./survey.modal.scss"]
})
export class SurveyModal extends BaseComponent implements OnInit, OnDestroy {
  @Input() surveyType = SurveyType.IndexSelection;
  @Input() bgImage = null;

  apiUrl = environment.apiUrl;
  modalRef: NgbModalRef;
  selectedAnswerId: number = 0;
  currentQuestionNum: number = 1;
  questionAmount: number = 0;
  isMobile: boolean = false;
  isTablet: boolean = false;
  questionModelDict: QuestionModelDict = new QuestionModelDict();
  surveyProgressDict: SurveyProgressModel = new SurveyProgressModel();
  surveyPostRequest: SurveyPostRequest = new SurveyPostRequest();
  showLoadingModal: boolean = false;
  showResultModal: boolean = false;
  indexInfo: IndexInfo = new IndexInfo();
  activeVideoUrl: string | null = null;
  prevVideoUrl: string | null = null;
  public walletAddress: string = "";
  tracksInfo: Track[] = [];
  isExpanded: boolean = false;
  loaderPercent: number = 0;
  requestLocked: boolean = false;
  currencyType = currencyType;

  artistCarouselBreakpoints: any = {
    "(min-width: 1px)": { slides: { perView: "auto" }}
  };

  public getStats: () => StatsModel;
  public getCryptoUser: () => CryptoUserModel;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private translate: TranslateService,
    private surveyService: SurveyService,
    private indexService: IndexService,
    private statsService: StatsService,
    private cryptoUserService: CryptoUserService,
    private router: Router,
    private walletService: WalletService,
    private trackService: TrackService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private eventService: EventService,
    private facebookAnalyticsService: FacebookAnalyticsService
  ) {
    super(translate, modalService)
    let t = this;

    t.isMobile = window.innerWidth < 570;
    t.isTablet = window.innerWidth < 950;
    window.onresize = () => {
      t.isMobile = window.innerWidth < 570;
      t.isTablet = window.innerWidth < 950;
    }

    t.getStats = () => {
      if (!!t.statsService.getStats()) {
        return t.statsService.getStats();
      }
      else return new StatsModel();
    };

    t.getCryptoUser = () => {
      let user = t.cryptoUserService.get();
      return user ?? new CryptoUserModel();
    }
  }

  ngOnInit(): void {
    let t = this;
    t.setLoading(true);
    document.addEventListener('click', t.onClickOutsideVideo.bind(t));
    Promise.all([
      t.getSurveyQuestionsByType(t.surveyType),
      t.getSurveyProgressByType(t.surveyType),
      t.loadBgImage()
    ])
    .finally(() => {
      if (!!t.surveyProgressDict && Object.keys(t.surveyProgressDict.answerOptionsDict).length) {
        t.currentQuestionNum = Math.min( Object.keys(t.surveyProgressDict.answerOptionsDict).length, t.questionAmount);
        // Проверяем, есть ли пропущенные вопросы между первым вопросом и текущим
        for (let i = 1; i < t.currentQuestionNum; i++) {
          if (!t.surveyProgressDict.answerOptionsDict[i]) {
            // Если есть пропущенные вопросы, отображаем первый из них
            t.currentQuestionNum = i;
            break;
          }
        }
      }

      // Проверяем наличие информации о прогрессе для текущего вопроса
      if (!!t.surveyProgressDict.answerOptionsDict && t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum])
        t.selectedAnswerId = t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum].answerId;
      t.setLoading(false);
    });
  }

  // для выключения прелоадера после того как загрузится фоновая картинка
  loadBgImage() {
    return new Promise<void>((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        resolve();
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = this.bgImage;
    });
  }

  getSurveyQuestionsByType(type: SurveyType) {
    var t = this;
    return t.surveyService.getSurveyQuestionsByType(type)
      .then( (resp) => {
        t.questionModelDict = resp.data;
        t.questionAmount = Object.keys(t.questionModelDict).length;
      })
      .catch((e) => {
        t.showResponseError(e);
        t.activeModal.close(false);
      });
  }

  getSurveyProgressByType(type: SurveyType) {
    var t = this;
    return t.surveyService.getSurveyProgressByType(type)
      .then( (resp) => {
        t.surveyProgressDict = resp.data;
        t.showResultModal = t.surveyProgressDict?.result != null;

        if (t.showResultModal) {
          t.getIndexesInfoByType(t.surveyProgressDict?.result);
          t.getComposition(t.surveyProgressDict?.result);
          t.cryptoUserService.refresh();
        }
      })
      .catch((e) => {
        t.showResponseError(e);
        t.activeModal.close(false);
      });
  }

  getNextQuestion() {
    let t = this;
    if (t.requestLocked) return;
    t.requestLocked = true;
    t.surveyPostRequest.questionNum = t.currentQuestionNum;
    t.surveyPostRequest.answerId = t.selectedAnswerId;
    t.surveyPostRequest.surveyType = t.surveyType;

    t.showLoadingModal = t.currentQuestionNum === t.questionAmount;

    t.surveyService.setSurveyProgress(t.surveyPostRequest)
      .then( (resp) => {
        t.surveyProgressDict = resp.data;
        t.showResultModal = t.surveyProgressDict?.result != null;
        if (t.showResultModal) {
          t.getIndexesInfoByType(t.surveyProgressDict?.result);
          t.getComposition(t.surveyProgressDict?.result);
        }

        if (t.showLoadingModal) {
          t.loaderPercentage();
          return;
        }

        t.currentQuestionNum += 1;
        t.selectedAnswerId = !!t.surveyProgressDict.answerOptionsDict && t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum] ?
          t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum].answerId : 0;

      })
      .catch((e) => {
        t.showResponseError(e);
        t.activeModal.close(false);
      })
      .finally( () => t.requestLocked = false );
  }

  getPrevQuestion() {
    let t = this;
    if (t.currentQuestionNum > 1) {
      t.currentQuestionNum--;
      // Проверяем наличие информации о прогрессе для предыдущего вопроса
      if (!!t.surveyProgressDict.answerOptionsDict && t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum]) {
        t.selectedAnswerId = t.surveyProgressDict.answerOptionsDict[t.currentQuestionNum].answerId;
      } else {
        t.selectedAnswerId = 0; // Сбрасываем значение, если нет информации о прогрессе для предыдущего вопроса
      }
    }
  }

  getIndexesInfoByType(type: IndexType) {
    let t = this;
    return t.indexService.getIndexesInfoByType(type)
      .then(response => {
        t.indexInfo = response.data[type];
        t.indexInfo.distributionStr = t.translate.instant("distribution." + t.indexInfo.distributionStr).replace("#distribution#",t.indexInfo.distribution);
      })
      .catch(ex => {
        t.showResponseError(ex);
      })
  }

  showPurchaseModal() {
    let t = this;
    t.setLoading(true);
    this.activeModal.close(true);
    setTimeout(() => {
      t.setLoading(false);
    },200);
    // если пользователь не верифицирован, то показываем модалку самсаба
    if (!t.getCryptoUser().isVerified) {
      t.modalRef = t.modalService.open(VerificateModal,
        {
          backdropClass: 'light-white-backdrop',
          centered: true,
          windowClass: 'super-modal-delete-users very-nice-shadow',
        });

      t.modalRef.result.then(result => {
        if (result)
          t.router.navigateByUrl("/verification");
      }).catch(() => {});
    }
    // иначе показываем модалку покупки
    else {
      t.statsService.refreshStats()
        .then(result => {
        t.modalRef = t.modalService.open(PurchaseModal,
          {
            backdropClass: 'light-white-backdrop',
            centered: true,
            size: 'md',
            windowClass: 'super-modal-delete-users very-nice-shadow'
          });

        t.modalRef.componentInstance.indexFeeModel = { title: t.translate.instant('Index amount') };
        t.modalRef.componentInstance.transFeeModel = { title: t.translate.instant('Network fee')};
        t.modalRef.componentInstance.tokenName = 'usd';
        t.modalRef.componentInstance.walletAddress = t.walletAddress;
        t.modalRef.result.then(result => {
          if (result) {
            t.buyToken(result);
          }
        }).catch(() => {});
      })
    }
    t.googleAnalyticsService.pushEvent('buy_index', 'btn_click', 'asset_growth');
    t.facebookAnalyticsService.trackEvent('Purchase');
  }

  public buyToken(buyRequest: BuyPostRequest) {
    let t = this;
    t.setLoading(true);
    t.walletService.buy(buyRequest)
      .then(resp => {
        t.showSuccess(t.translate.instant("Your transaction has been initiated. Typically, it takes about 5-20 minutes to complete. Please wait") + ".", "SYSTEM");
        t.googleAnalyticsService.pushEvent('success_buy', 'btn_click', 'asset_growth');
        t.facebookAnalyticsService.trackPurchaseEvent(buyRequest.buyTokenAmount, t.currencyType.find(x => x.value === buyRequest.currencyType).label);
      })
      .catch((e) => {
        t.showResponseError(e);
      })
      .finally(() => {
        t.setLoading(false);
      });
  }

  showDepositModal() {
    let t = this;
    t.modalRef = t.modalService.open(DepositModal,
      {
        backdropClass: 'light-white-backdrop',
        centered: true,
        size: 'md',
        windowClass: 'super-modal-delete-users very-nice-shadow'
      });

    t.modalRef.componentInstance.walletAddress = t.walletAddress;
  }

  getWalletAddressInfo() {
    var t = this;
    return t.walletService.getInfo()
      .then((resp) => {
        if (!!resp.data) {
          t.walletAddress = resp.data;
        }
      }).catch((e) => {
        t.showResponseError(e);
      });
  }

  getComposition(indexId: number) {
    let t = this;
    let filterModel: TracksFilterModel = {
      search: "",
      indexType: indexId
    }
    return t.trackService.getComposition(filterModel)
      .then(resp => {
        t.tracksInfo = resp.data;
      })
      .catch(ex => {
        t.showResponseError(ex);
      });
  }

  showVideo(videoUrl: string) {
    this.activeVideoUrl = videoUrl;
  }

  playVideo() {
    let videoElement = document.getElementById('videoElement') as HTMLVideoElement;
    if (videoElement) {
      videoElement.muted = false;
      let promise = videoElement.play();
      if(promise !== undefined){
        promise.then(() => {
          // Autoplay started
        }).catch(error => {
          // Autoplay was prevented.
          videoElement.muted = true;
          videoElement.play();
        }).finally( () => {
          // костыль для старых версий mac, т.к там на первый фрейм видео не срабатывает object-fit
          setTimeout(() => {
            videoElement.style.opacity = '1';
          }, 300);
        });
      }
    }
  }

  hideVideo() {
    this.activeVideoUrl = null;
  }

  onClickOutsideVideo(event: MouseEvent) {
    var t = this;
    let target = event.target as Element;
    // Проверяем, был ли клик совершен вне области видео
    if (!target.closest('.video-mask') && !target.closest('.show-video-btn')) {
      t.prevVideoUrl = null;
      t.hideVideo();
    }
  }

  toggleExpansion(): void {
    var t = this;
    t.isExpanded = !t.isExpanded;
  }

  play(uri: string) {
    this.eventService.SongChangeEvent.emit(uri);
  }

  loaderPercentage() {
    let loaderInterval = setInterval(() => {
      let t = this;
      t.loaderPercent += 1;
      if (t.loaderPercent >= 100) {
        clearInterval(loaderInterval);
        t.cryptoUserService.refresh();
        t.showLoadingModal = false;
      }
    }, 30);
  }

  closeModal() {
    let t = this;
    t.activeModal.close(false);
  }


  ngOnDestroy(): void {
    var t = this;
    document.removeEventListener('click', t.onClickOutsideVideo.bind(t));
  }
}
