<div class="card-header">
  <div class="row">
    <div class="col" [ngClass]="headerClasses">{{header | lang}}</div>
    <div class="col-auto d-flex gap-2" *ngIf="showSwitches">
          <img id="left" src="assets/images/ripe/arrow-left.svg"  (click)="slider.prev()" class="pointer">
          <img id="right" src="assets/images/ripe/arrow-right.svg" (click)="slider.next()" class="pointer">
    </div>
</div>

<div class="row theme-form mt-3" *ngIf="showSearch">
  <div class="col">
    <div class="search-input-container d-flex py-2">
      <img src="assets/images/ripe/search.svg">
      <input type="text" class="search-input w-100" placeholder="Find" #searchInput (keyup.enter)="search(searchInput.value)">
    </div>
  </div>
    <div class="col-auto">
        <button class="btn btn-primary h-100" (click)="goToTracks()">{{'See more'|lang}}
          <img class="ml-2" src="assets/images/ripe/right-icon-black.svg">
        </button>
    </div>
</div>
</div>
<div class="card-body h-100 pb-5">
  <div class="row mt-3 h-100">
    <div class="col h-100">
      <div class="navigation-wrapper h-100">
        <div class="h-100 keen-slider keen-slider--vertical carousel-scrollbar" #sliderRef id="sliderRef">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>