import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FacebookAnalyticsService {
  environment = environment;

  trackPurchaseEvent(value: number, currency: string) {
    if (this.environment.enableFacebookAnalytics) {
      (<any>window).fbq('track', 'Purchase', {
        value: value,
        currency: currency
      });
    }
  }

  trackEvent(contentType: string, currency: string = "") {
    if (this.environment.enableFacebookAnalytics) {
      (<any>window).fbq('track', contentType, {
        currency: currency
      });
    }
  }

}