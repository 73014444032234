<div class="auth-block">
  <div class="auth-bg-title align-center-900 d-none-400">{{"Two-factor" | lang}}</div>
  <div class="auth-bg-title align-center-900 d-none-400">{{"Authentification" | lang}}</div>
  <div class="card-body"> 
    <label class="login-header align-center-900 text-center text-sm-start">
      {{"Enter below the 6-digit code you received on {twoFactor}" | lang: [receptionType]}}
    </label>
    <form class="theme-form" [formGroup]="confirmCode">
      <div class="row">
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="1" formControlName="input1" maxlength="1" placeholder="1"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(1)" (keyup)="handler($event, 1)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="2" formControlName="input2" maxlength="1" placeholder="2"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(2)" (keyup)="handler($event, 2)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="3" formControlName="input3" maxlength="1" placeholder="3"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(3)" (keyup)="handler($event, 3)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="4" formControlName="input4" maxlength="1" placeholder="4"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(4)" (keyup)="handler($event, 4)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="5" formControlName="input5" maxlength="1" placeholder="5"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(5)" (keyup)="handler($event, 5)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
        <div class="col form-group input-container">
          <div class="input-group text-box">
            <input class="form-control confirm-code-input" id="6" formControlName="input6" maxlength="1" placeholder="6"
              NumbersOnly="true" autocomplete="one-time-code" type="number" 
              (keydown.backspace)="back(6)" (keyup)="handler($event, 6)" (keydown)="keyDownHandler($event)" (paste)="checkPaste($event)">
          </div>
        </div>
      </div>
    </form>

    <div class="text text-danger mt-1 d-flex" *ngIf="showErrorText">
      <span class="m-auto">
        <img src="assets/images/danger.svg" class="mr-1"/>
        <span class="confirmation-code-error-text">{{'Incorrect code'|lang}}</span>
      </span>
    </div>
    <div class="desktop-show auth-card-footer">
      <a class="c-green" href="javascript:void(0)"
      (click)="navigateTab('login')">{{"Back to Sign In" | lang}}</a>
    </div>
  </div>
</div> 