import { RegexUtils } from '.';


export class ValidationUtils {

    /**
     * Проверка что фамилия, имя или отчество кашерное.
     * В случае некашерности возвращает IValidationResult с текстом ошибки.
     */
    public static validateName(val: string): IValidationResult {

        if (RegexUtils.isNullOrWhitespace(val)) {
            return {
                isValid: false,
                errorText: 'errors.required'
            };
        }

        var latinAndCyrillic = val.replace(/[^a-zA-Zа-яА-ЯёЁäöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍ-]/i, "");
        if (latinAndCyrillic.length < val.length) {
            return {
                isValid: false,
                errorText: 'errors.latinAndCyrillic'
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    public static validateNumber(val: string): IValidationResult {

        if (isNaN(+val) || val == null || this.isEmpty(val)) {
            return {
                isValid: false,
                errorText: 'errors.notNumber'
            }
        }

        return {
            isValid: true,
            errorText: ""
        };

    }

    public static validateText(val: string): IValidationResult {

        if (val != null) {
            val.split("").forEach(element => {
                if (!isNaN(+element) || element == null || this.isEmpty(element)) {
                    return val = "error";
                }
            });
            if (val == "error")
                return {
                    isValid: false,
                    errorText: 'errors.onlyText'
                }
        }
        return {
            isValid: true,
            errorText: ""
        };
    }

    public static isEmpty(str) {
        return (!str || str.length === 0);
    }

    /**
     * Проверка что электронная почта кашерная.
     * В случае некашерности возвращает IValidationResult с текстом ошибки.
     */
    public static validateEmail(val: string): IValidationResult {

        if (RegexUtils.isNullOrWhitespace(val)) {
            return {
                isValid: false,
                errorText: 'errors.required'
            };
        }

        if (!RegexUtils.isValidEmail(val)) {
            return {
                isValid: false,
                errorText: 'errors.invalid'
            }
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    /**
     * Проверка что номер телефона кашерный.
     * В случае некашерности возвращает IValidationResult с текстом ошибки.
     */
    public static validatePhone(val: string): IValidationResult {

        if (RegexUtils.isNullOrWhitespace(val)) {
            return {
                isValid: false,
                errorText: 'errors.required'
            };
        }

        if (!RegexUtils.isValidPhone(val)) {
            return {
                isValid: false,
                errorText: 'errors.invalid'
            }
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    public static validateNotEmpty(val: string): IValidationResult {

        if (RegexUtils.isNullOrWhitespace(val)) {
            return {
                isValid: false,
                errorText: 'errors.required'
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    /** валидация пароля */
    public static validatePassword(password: string): IValidationResult {
        if (RegexUtils.isNullOrWhitespace(password)) {
            return {
                isValid: false,
                errorText: "errors.required"
            };
        }

        if (!/[A-ZА-ЯЁ]/.test(password)) {
            return {
                isValid: false,
                errorText: "errors.password.atLeastOneUpperCase"
            };
        }

        if (!/[a-zа-яё]/.test(password)) {
            return {
                isValid: false,
                errorText: "errors.password.atLeastOneLowerCase"
            };
        }

        if (/[А-ЯЁ]/.test(password) || /[а-яё]/.test(password)) {
            return {
                isValid: false,
                errorText: "errors.password.noCyrillic"
            };
        }

        if (!/\d/.test(password)) {
            return {
                isValid: false,
                errorText: "errors.password.atLeastOneDigit"
            };
        }

        if (password.length < 6) {
            return {
                isValid: false,
                errorText: "errors.password.minLength"
            };
        }

        if (password.length > 100) {
            return {
                isValid: false,
                errorText: "errors.password.maxLength"
            };
        }

        if (!RegexUtils.isLatinNumberSymbols(password)) {
            return {
                isValid: false,
                errorText: "errors.password.incorrectSymbols"
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    /** валидация подтверждения пароля */
    public static validatePasswordConfirmation(password: string, confirmation: string): IValidationResult {
        if (RegexUtils.isNullOrWhitespace(confirmation)) {
            return {
                isValid: false,
                errorText: "errors.required"
            };
        }

        if (password !== confirmation) {
            return {
                isValid: false,
                errorText: "errors.passwordConfirmation.notMatch"
            }
        }

        return {
            isValid: true,
            errorText: ""
        };
    }


    /** валидация старого пароля */
    public static validatePasswordOld(password: string): IValidationResult {

        if (RegexUtils.isNullOrWhitespace(password)) {
            return {
                isValid: false,
                errorText: "errors.oldPassword.FillOld"
            };
        }


        return {
            isValid: true,
            errorText: ""
        };
    }


    /**
     * Валидация введенного кода подтверждения
     */
    public static validateConfirmationCodeOnlyRequired(code: string): IValidationResult {
        if (RegexUtils.isNullOrWhitespace(code)) {
            return {
                isValid: false,
                errorText: "errors.required"
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    /**
     * Валидация введенного кода подтверждения
     */
    public static validateConfirmationCode(code: string): IValidationResult {
        if (RegexUtils.isNullOrWhitespace(code)) {
            return {
                isValid: false,
                errorText: "errors.required"
            };
        }

        if (!RegexUtils.isValidConfirmationCode(code)) {
            return {
                isValid: false,
                errorText: "errors.confirmationCode.invalid"
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    public static validateEndDate(startDate: Date, endDate: Date): IValidationResult {
        if (startDate>endDate) {
            return {
                isValid: false,
                errorText: "errors.invalidEndDate"
            };
        }

        return {
            isValid: true,
            errorText: ""
        };
    }

    /** валидация даты. Дата старта не была больше даты окончания периода*/
    public static validateDate(date: any): IValidationResult {
        var today = new Date();
        if (date > today || date == "Invalid Date") {
            return {
                isValid: false,
                errorText: "Invalid date"
            }
        }
        return {
            isValid: true,
            errorText: ""
        };
    }

    /**
    * Обработчик ввода для поля ввода, ограничивающий ввод символов в зависимости от условий.
    * @param ev - объект события ввода
    * @param ngInput - текущее значение поля ввода
    * @param roundAfterDot - количество знаков после запятой, до которого ограничивается ввод
    * @param maxAllowed - максимальное допустимое значение для поля ввода
    * @param minAllowed - минимальное допустимое значение для поля ввода
    * @param allowComma - разрешает использование запятой
    */
    public static digitsWithSeparatorInputHandler( ev: any, ngInput: string, roundAfterDot: number = Number.MAX_VALUE,
        maxAllowed: number = Number.MAX_VALUE, minAllowed: number = 0, allowComma: boolean = true
    ) {
        var accessDot = '.';
        var accessComma = ',';
        var accessKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', accessDot, 'Backspace'];
        var accessKeys2 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace'];
        var accessDots = [accessDot];
        if (allowComma) {
            accessKeys.push(accessComma);
            accessDots.push(accessComma);
        }
        var inputStrFirst = ngInput.split(/[.,]+/g)[0];
        var inputStrSecond = !!ngInput.split(/[.,]+/g)[1] ? ngInput.split(/[.,]+/g)[1] : '';
        var hasDot = ngInput.indexOf(accessDot) != -1 || ngInput.indexOf(accessComma) != -1;
        // ev.target.selectionStart работает только с input типа text
        var cursorBeforeDot =
        ngInput.indexOf(accessDot) != -1
            ? ev.target.selectionStart < ngInput.indexOf(accessDot) + 1
            : ev.target.selectionStart < ngInput.indexOf(accessComma) + 1;
        // Проверяем, что событие ввода не backspace и введенный символ не находится в списке разрешенных символов
        // или нарушается хотя бы одно из следующих условий:
        // - недопустимый первый символ в случае
        // - недопустимое количество цифр после точки
        // - вводимое значение превышает максимальное или меньше минимального
        if ( ev.key != 'Backspace' &&
            (accessKeys.indexOf(ev.key) == -1 ||
            this.isInvalidFirstInput(inputStrFirst, ev.key, accessKeys2, hasDot, accessDots, cursorBeforeDot) ||
            this.isInvalidSecondInput(cursorBeforeDot, inputStrSecond, roundAfterDot) ||
            this.isInputExceedingMinMaxValue(ngInput, maxAllowed, minAllowed))
        ) {
        ev.preventDefault();
    }
  }

  /// Проверяем, что первый введенный символ не является точкой/запятой и не нарушает ограничения на ввод
  private static isInvalidFirstInput( inputStrFirst: string, key: string, accessKeys2: string[],
    hasDot: boolean, accessDots: string[], cursorBeforeDot: boolean ): boolean {
    return (
      (inputStrFirst == '' && accessKeys2.indexOf(key) == -1) || // Проверка на первый символ
      (inputStrFirst.charAt(0) == '0' && key == '0' && cursorBeforeDot) || // Проверка на ввод нуля перед точкой
      (!cursorBeforeDot && accessDots.indexOf(key) != -1 && hasDot) // Проверка на количество точек
    );
  }

  // Проверяем количество цифр после запятой
  private static isInvalidSecondInput( cursorBeforeDot: boolean, inputStrSecond: string, roundAfterDot: number): boolean {
    return !cursorBeforeDot && inputStrSecond.length >= ('' + roundAfterDot).length - 1;
  }

  // Проверяем, что вводимое значение находится в диапазоне между минимальным и максимальным значениями
  private static isInputExceedingMinMaxValue( ngInput: string, maxAllowed: number, minAllowed: number ): boolean {
    return +ngInput >= maxAllowed || +ngInput < minAllowed;
  }
    
}

/**
 * Результат валидации
 */
export interface IValidationResult {
    /**
     * Валидно/невалидно
     */
    isValid: boolean;
    /**
     * Текст сообщения об ошибке
     */
    errorText: string;
}