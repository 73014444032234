import { Widget, WidgetConfig } from '@rango-dev/widget-embedded';
import * as React from 'react';
import { FunctionComponent, useEffect} from 'react';

export interface RangoWidgetProps {
  config: WidgetConfig;
  onLoad?: any;

}

export const CustomReactButton: FunctionComponent<RangoWidgetProps> = (props: RangoWidgetProps) => {
  const { config, onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <Widget config={config} />
    </div>
  );
};