export enum WalletTransStatus {
        
    NotActive = -300,

    /** Все статусы для фильтра */
    All = -200,

    Default = -1,

    /** В ожидании */
    Pending = 0,

    /** Успешно */
    Success = 1,

    Error = 2,

    Declined = 3,

    BalanceIsLow = 4,

    UncheckedDeposit = 5,

    HighRiskTransfer = 6,

    WaitingApproval = 101,

    ApprovedSending = 200,
}