import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { EventService, IndexService } from '../../services';
import { Router } from '@angular/router';
import { IndexType } from '../../enums';

@Component({
  selector: 'app-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;

  @Input() showSearch: boolean = false;
  @Input() showSwitches: boolean = true;
  @Input() trackCount: number = 0;
  @Input() header: string = '';
  @Input() indexType: IndexType.Any;
  @Input() loop: boolean = true;
  @Input() useResizePlugin: boolean = true;
  @Input() useWheelPlugin: boolean = true; //настройки чтоб горизонтальный скрол работал
  @Input() headerClasses: string = '';
  
  /// брейкпойнты перезаписывают не один конкретный параметр для слайдов, а все сразу,
  /// поэтому нужно отправлять все параметры
  /// по дефолту специально null, чтобы не ломать метод для карусели треков
  @Input() breakpoints: any = null;

  currentSlide: number = 1;
  dotHelper: Array<Number> = [];
  slider: KeenSliderInstance = null;
  loaded: boolean[] = [true];
  searchStr: string = '';

  // Объект таймера, запускающего обработчик ресайза.
  resizeTimer: any;

  constructor(
    private eventService: EventService,
    private router: Router,
    private indexService: IndexService
  ) {}
  ngAfterViewInit(): void {
    let t = this;
    let plugins = [];
    if (t.useWheelPlugin) plugins.push(WheelControls); 
    if (t.useResizePlugin) plugins.push(ResizePlugin);

    setTimeout(() => {
      //setTimeout чтоб в конце eventLoop выполнилось
      t.slider = new KeenSlider(
        t.sliderRef.nativeElement,
        {
          rubberband: false,
          breakpoints: t.breakpoints,
          slides: {
            perView: () => {
              return t.calcCardsPerView();
            },
            spacing: 20,
          },
          mode: 'free',
          loop: t.loop,
        },
        plugins 
      );
    }, 0);
  }

  calcCardsPerView(): number {
    let sliderWidth = this.sliderRef.nativeElement.offsetWidth;
    let cardPerView = this.trackCount;
    // Ищем такое количество карточек в области видимости,
    // чтобы ширина одной карточки не превышала N пикселей,
    // где N - минимальная ширина карточки.
    while (cardPerView > 1) {
      if (Math.floor(sliderWidth / cardPerView) >= 350)
        break;
      cardPerView--;
    }
    return cardPerView;
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  search(value) {
    let t = this;
    t.eventService.SeacrhCarouselEvent.emit(value);
  }

  goToTracks() {
    let t = this;
    t.indexService.changeIndexType(t.indexType);
    t.router.navigateByUrl('/tracks');
  }
}

const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    // За одно изменение размера окна событие может сработать много раз,
    // при этом вызывая довольно тяжелый обработчик.
    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => slider.update(), 200);
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });

  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const WheelControls = (slider) => {
  let touchTimeout;
  let position;
  let wheelActive;

  function dispatch(e, name) {
    //листаем вертикально поэтому берем сдвиг по оси Y
    //делится на 2 потому что слишком быстро листает по дефолту
    position.x -= e.deltaY / 2;
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      })
    );
  }

  function wheelStart(e) {
    position = {
      x: e.pageX,
      y: e.pageY,
    };
    dispatch(e, 'ksDragStart');
  }

  function wheel(e) {
    dispatch(e, 'ksDrag');
  }

  function wheelEnd(e) {
    dispatch(e, 'ksDragEnd');
  }

  function eventWheel(e) {
    e.preventDefault();
    if (!wheelActive) {
      wheelStart(e);
      wheelActive = true;
    }
    wheel(e);
    clearTimeout(touchTimeout);
    touchTimeout = setTimeout(() => {
      wheelActive = false;
      wheelEnd(e);
    }, 500);
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    });
  });
};
