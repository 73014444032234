
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: "confirm.modal.html",
  styleUrls: ["./confirm.modal.scss"]
})
export class ConfirmModal implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() showDescription: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() buttonConfirm: string = '';
  @Input() showConfirmButton: boolean = true;
  @Input() buttonDecline: string = '';
  @Input() buttonDeclineFontSize: string;
  @Input() showDeclineButton: boolean = true;
  @Input() buttonError: string = '';
  @Input() showErrorButton: boolean = false;
  public showButtons: boolean = true;
  
  public descriptionHTML: SafeHtml;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    var t = this;
    t.descriptionHTML = t.sanitizer.bypassSecurityTrustHtml(t.description);
     document.addEventListener('touchmove', e=>e.preventDefault(), {passive: false});

    t.showButtons = t.showConfirmButton || t.showDeclineButton || t.showErrorButton;
  }


  ngOnDestroy() : void {
    document.removeAllListeners('touchmove');
  }

  public isConfirm(resp: boolean) {
    this.activeModal.close(resp);
  }
}