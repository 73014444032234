import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { NavService } from '../../services/nav.service';
import { environment } from 'src/environments/environment';
import { SpotifyPlayerComponent } from '../spotify-player/spotify-player.component';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    // затемнение при навигации между страницами
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      params: { timing: 0}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit {

  @ViewChild('player') player: SpotifyPlayerComponent;

  environment = environment;
  constructor(
    public navServices: NavService
  ) { }

  ngOnInit(): void {
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  // public hideMenu() {
  //   this.navServices.collapseSidebar = true;
  //   this.navServices.collapseHeaderInfo = true;
  // }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.calculatePageBodyWrapperWidth();
  }

  // пересчет размеров pageBodyWrapper
  calculatePageBodyWrapperWidth() {
    const sidebarBlockWidth = document.getElementById('sidebar-wrapper').offsetWidth;
    const pageBodyWrapperWidth = document.getElementById('pageBodyWrapper').offsetWidth;
    const pageBodyWidth = pageBodyWrapperWidth - sidebarBlockWidth;
    document.getElementById('page-body').style.width = `${pageBodyWidth}px`;
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

}
