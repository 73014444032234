<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div id="page-wrapper" class="page-wrapper">
    <ng-container *ngIf="!environment.buildAdmin">
      <app-header></app-header>
    </ng-container>
    <ng-container *ngIf="environment.buildAdmin">
      <app-admin-header></app-admin-header>
    </ng-container>
    <!-- Page Body Start-->
    <div id="pageBodyWrapper" #pageBodyWrapper class="page-body-wrapper d-flex position-relative" 
      [ngClass]="{'colapsedSidebar': !navServices.collapseSidebar, 
                  'openSidebar' : navServices.collapseSidebar }">
      <div class="black-overlay mobile-show" (click)="collapseSidebar()"></div>
      <div id="sidebar-wrapper" #sidebarBlock>
        <!-- *ngIf="!!navServices", чтобы меню не появлялось до проставления класса openSidebar или colapsedSidebar -->
        <app-sidebar *ngIf="!!navServices"></app-sidebar>
      </div>
      <!-- <div class="myblocker" (click)="hideMenu()" [hidden] ="navServices.collapseSidebar && navServices.collapseHeaderInfo">
      </div> -->
      <div [style.width.px]="pageBodyWrapper.offsetWidth - sidebarBlock.offsetWidth" 
        id="page-body" class="page-body d-flex flex-column justify-content-between">
        <div> <!-- див для того чтобы justify-content-between, норм раздялал -->
          <app-breadcrumb></app-breadcrumb>
          <main [@animateRoute]="getRouterOutletState(o)">
            <router-outlet #o="outlet"></router-outlet>
          </main>
        </div>
        
        <!-- footer start-->
        <footer class="footer mt-4 pb-0 pl-0 l-h-100">
          <app-footer></app-footer>
        </footer>
        <!-- footer End-->
      </div>
    </div>
    <!-- Page Body End-->
  </div>
  <app-spotify-player #player></app-spotify-player>
</div>
