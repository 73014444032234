import { CurrencyType, WithdrawModalType } from "../../enums";

export class WithdrawModalModel {    
    CurrencyType: CurrencyType = CurrencyType.USDT;
    Type: WithdrawModalType;
    Amount: number;
    GasLimit?: any;

    AddressTo?: string;
    AddressToInfo?: string;

    ResultText?: string = "Total";
    ResultTextInfo?: string;

    BtnText?: string;

    fixedAmount: boolean = false;
    fixedAddressTo: boolean = false;
        
    showConfirmationCode: boolean = true;
    showNetworkFee: boolean = true;    
}