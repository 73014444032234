export class CryptoUserFilterModel {
    seach : string;
    status : number;
    userRefStatus : number;
    programId: number;
    skip : number;
    typeTransaction : number;
    buyNumber: number;
    invited : string;
    seachIp : string;
    buyerEmail : string;
    programType: number;
    verificationStatus: number;
    refLinkType: number;
}