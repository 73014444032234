export enum TwoFactorAuthorizationType {
    
    Email = -1,
    /// <summary>
    /// без двуфакторки
    /// </summary>    
    Default = 0,
    
    SMS = 1,

    GoogleAuth = 2
}