<!-- Page Header Start-->
<div class="page-main-header" id="page-main-header">
  <div class="main-header-right row">
    <div class="ripe-logo col-auto" style="padding: 13px 11px 13px 15px;">
      <img src="assets/images/ripe/Logo_cropped.svg"/>
    </div>
    
    <div class="nav-right header-nav-right-custom col">
      <ul class="nav-menus fontsize gap-3" style="float: right;">

        <div class="media align-items-center">
          <img class="avatar-circle-36" src="{{getAvatar()}}">
        </div>
        <li (click)="authService.SignOut()">
          <img class="pointer" src="assets/images/ripe/log_out_icon.svg">
        </li>

      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->