import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, PublicService } from '../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { languages} from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resetpass',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPassComponent extends BaseComponent implements OnInit {

  public forgotPassForm: FormGroup;
  public currentUserLang: string;
  languages = languages;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private publicService: PublicService,
    private translate: TranslateService,
  ) {
    super(translate, modalService)

    this.forgotPassForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() { return this.forgotPassForm.get('email'); }

  ngOnInit(): void {
    var t = this;
    var lang = t.getCurrentLocalization();
    t.translate.use(lang);
  }

  public resetPass() {
    var t = this;
    if (t.forgotPassForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.forgotPassForm)
      return;
    }
    t.setLoading(true);
    localStorage.removeItem("telegramAuthData");

    t.publicService.restorePass({ email: t.email.value, localization: t.getCurrentLocalization() })
      .then((resp: any) => {
        t.showSuccess(t.translate.instant("Please check your email") + ".<br>" + t.translate.instant("New password sent to your email") + " " + t.email.value, "SYSTEM");
        t.setLoading(false);
        t.router.navigate(['/auth/login']);
      }).catch((e) => {
        t.setLoading(false);
        t.showResponseError(e);
      });
  }

  public navigateTab(route: string){
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }
}
