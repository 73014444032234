<div class="auth-block">
  <div class="auth-bg-title align-center-900 text-center text-sm-start">{{"Forgot" | lang}}</div>
  <div  class="auth-bg-title align-center-900 text-center text-sm-start">{{"Password" | lang}}?</div>
  <div class="card-body"> 
    <label class="login-header align-center-900 text-center text-sm-start">
      {{"Enter the email address you used when you created the account, and we’ll send you a code to reset your password" | lang}}
    </label>
    <form class="theme-form" [formGroup]="forgotPassForm">
      <div class="form-group">
        <input type="email" class="form-control" formControlName="email" type="email" placeholder="{{'Email' | lang}}" required="" autofocus="">
        <div *ngIf="elemIsInvalid(forgotPassForm.controls.email)" class="text text-danger mt-1">
          {{textErrorStr(forgotPassForm.controls.email)}}
        </div>
      </div>        

      <div class="form-group pt-2">
          <button class="btn btn-primary btn-block" (click)="resetPass()">
            {{"Submit" | lang}}
          </button>
      </div>

      <div class="desktop-show auth-card-footer">
        <a class="c-green" href="javascript:void(0)" (click)="navigateTab('login')">
          {{"Back to Sign In" | lang}}
        </a>
      </div>
    </form>
  </div>
</div> 

