export enum MediaPlatform {  
    Any = -200, 
    Spotify = 0,
    Instagram = 1,
    TikTok = 2,
    YouTube = 3,
    SoundCloud = 4,
    AppleMusic = 5,
    Amazon = 6,
    Deezer = 7,
    Shazam = 8,
    iTunes = 9,
    Tidal = 10
}