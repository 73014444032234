import { environment } from 'src/environments/environment';

const usdtArbitrumAsset: any = {
    symbol: 'USDT',
    address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    blockchain: 'ARBITRUM'
};

const arbitrumToConfig: any = {
    blockchain: 'ARBITRUM',
    token: usdtArbitrumAsset,
    blockchains: [
        'ARBITRUM'
    ],
    tokens: {
        ARBITRUM: {
            isExcluded: false,
            tokens: [
                usdtArbitrumAsset
            ]
        }
    },
    pinnedTokens: [usdtArbitrumAsset]
}

const ripeTheme: any = {
    mode: 'dark',
    borderRadius: 19,
    secondaryBorderRadius: 12,
    colors: {
      dark: {
        neutral: '#181818',
        primary: '#44d62c',
        secondary: '#255720',
        background: '#000000',
        foreground: '#ffffff',
        info: '#31ec1c'
      }
    },
    singleTheme: true,
    fontFamily: 'Roboto'
}

export const widgetConfig: any = {
    apiKey: environment.rangoApiKey,
    affiliate: {
        ref: 'SboqZP',
        percent: 1.0
    },
    walletConnectProjectId: environment.walletConnectProjectId,
    to: arbitrumToConfig,
    theme: ripeTheme,
    multiWallets: true,
    customDestination: true,
    externalWallets: false,
    defaultCustomDestinations: {
        'ARBITRUM': ''
    }
}
