import { ApiService } from "./api.service";
import { IResponse } from "../interfaces";
import { BonusAmountGetRequestModel, ClaimBonusPostRequestModel } from "../model";
import { Injectable } from "@angular/core";
import { BonusAmountModel } from "../model/bonusModels/bonusAmountModel";

const apiBonusUrl = 'api/bonus';
@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(private api: ApiService) { }

  public Claim(request: ClaimBonusPostRequestModel): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(apiBonusUrl + "/claim", request).toPromise();
  }

  public GetBonusAmount(request: BonusAmountGetRequestModel): Promise<IResponse<BonusAmountModel>> {
    return this.api.post<BonusAmountModel>(apiBonusUrl + "/getBonusAmount", request).toPromise();
  }
}